import React from 'react'

class Nav extends React.Component {
  render () {
    return (
      <div className='nav'>
        <a href='/'><h1>Corey Eats Rice</h1></a>
        <ul className='link-list'>
          <li><a href='https://www.github.com/coreyar'><img src={'/images/github.png'} /></a></li>
          <li><a href='https://archive.org/details/InfoMusicAmerikane'><img src={'/images/internet-archive.png'} /></a></li>
        </ul>
      </div>
    )
  }
}

export default Nav
