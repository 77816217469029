import React, { ReactElement } from 'react'
import { Router, Route, Switch, RouteComponentProps } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Nav } from '@/components'
import { Home, NotFound } from '@/pages'

require('./../styles/main.scss')

const history = createBrowserHistory()

const Routes= () => (
  <Router history={history}>
    <div>
      <Nav />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/*' component={NotFound} contextData={{ is404: true }} />
      </Switch>
    </div>
  </Router>
)

export default Routes
