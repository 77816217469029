import React from 'react'
const emptyBowl = '/images/empty-bowl.png'

const NotFound = () => (
  <div className='four-o-four'>
    <h1>404 Oh No! This bowl is empty</h1>
    <img src={emptyBowl} />
  </div>
)

export default NotFound
