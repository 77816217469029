import React from 'react'

class Footer extends React.Component {
  render () {
    return (
      <div className='footer'>
        <hr className='hr' />
      </div>
    )
  }
}

export default Footer
