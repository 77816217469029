import React from 'react'
import Gallery, { ImageOptions } from 'react-grid-gallery'


type HomeProps = {
}

type HomeState = {
  picInfo: ImageOptions,
}

const SECRET_IMAGES: Array<ImageOptions>  = [
  {
    src: '/images/20170902_231548.jpg',
    thumbnail: '/images/20170902_231548.jpg',
    thumbnailWidth: 2667,
    thumbnailHeight: 1500,
    caption: [
      <h1 key='when'>September 1, 2017</h1>,
      <h1 key='where'>Theron and Courtney's wedding</h1>,
      <h1 key='who'>with Molly</h1>
    ]
  },
  {
    src: '/images/20170902_231637.jpg',
    thumbnail: '/images/20170902_231637.jpg',
    thumbnailWidth: 2000,
    thumbnailHeight: 3556,
    caption: [
      <h1 key='when'>September 1, 2017</h1>,
      <h1 key='where'>Theron and Courtney's wedding</h1>,
      <h1 key='who'>with Jon</h1>
    ]
  }
]
const IMAGES = [
  {
    src: '/images/20171015_215100.jpg',
    thumbnail: '/images/20171015_215100.jpg',
    thumbnailWidth: 1250,
    thumbnailHeight: 740,
    caption: [
      <h1 key='when'>October 15, 2017</h1>,
      <h1 key='where'>Kourtney's Birthday</h1>,
      <h1 key='who'>with many friends</h1>
    ]
  },
  {
    src: '/images/20170901_201809.jpg',
    thumbnail: '/images/20170901_201809.jpg',
    thumbnailWidth: 1328,
    thumbnailHeight: 747,
    caption: [
      <h1 key='when'>September 1, 2017</h1>,
      <h1 key='where'>Camp Marymount</h1>,
      <h1 key='who'>with Griffen Wenzler</h1>
    ]
  },
  {
    src: '/images/20170901_201015.jpg',
    thumbnail: '/images/20170901_201015.jpg',
    thumbnailWidth: 1328,
    thumbnailHeight: 747,
    caption: [
      <h1 key='when'>September 1, 2017</h1>,
      <h1 key='where'>Camp Marymount</h1>,
      <h1 key='who'>with Tim Duggan</h1>
    ]
  },
  {
    src: '/images/20170719_203527.jpg',
    thumbnail: '/images/20170719_203527.jpg',
    thumbnailWidth: 666,
    thumbnailHeight: 518,
    caption: [
      <h1 key='when'>July 19, 2017</h1>,
      <h1 key='where'>in East Nashville</h1>,
      <h1 key='who'>with Richie Cordaro, Fabineen Saayman</h1>
    ]
  },
  {
    src: '/images/20170613_204332.jpg',
    thumbnail: '/images/20170613_204332.jpg',
    thumbnailWidth: 320,
    thumbnailHeight: 174,
    caption: [
      <h1 key='when'>June 13, 2017</h1>,
      <h1 key='where'>at Nomzilla</h1>,
      <h1 key='who'>with Austin Baumen</h1>
    ]
  },
  {
    src: '/images/1015024526841.jpg',
    thumbnail: '/images/1015024526841.jpg',
    thumbnailWidth: 640,
    thumbnailHeight: 483,
    caption: [
      <h1 key='when'>April 20, 2011</h1>,
      <h1 key='where'>in Pajovë, Albania</h1>,
      <h1 key='who'> with Tasim Plaku, Alex Toussant, Alex Lee</h1>
    ]
  }
]

const INITIAL_PIC_INFO = {
  caption: []
}

class Home extends React.Component<HomeProps, HomeState> {

  secretCode: string

  pressed: Array<string>

  constructor (props: HomeProps) {
    super(props)
    this.state = {
      picInfo: INITIAL_PIC_INFO
    }
    this.secretCode = 'drunkenfriedrice'
    this.pressed = []
  }

  componentWillMount () {
    window.addEventListener('keydown', this.handleKey)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleKey)
  }

  handleKey = (e: KeyboardEvent): any => {
    this.pressed.push(e.key)
    this.pressed.splice(-this.secretCode.length - 1, this.pressed.length - this.secretCode.length)
    this.forceUpdate()
  }

  openLightbox (i: number) {
    this.setState({ picInfo: IMAGES[i] })
    let box: HTMLElement | null = document.getElementById('pic-overlay')
    console.log({ box })
    if (box) {
      box.classList.toggle('apply-overlay')
    }
  }

  closeLightBox () {
    let box: HTMLElement | null = document.getElementById('pic-overlay')
    if (box) {
      box.classList.toggle('apply-overlay')
    }
    this.setState({ picInfo: INITIAL_PIC_INFO })
  }

  render () {
    const images: Array<ImageOptions> = this.pressed.join('') === this.secretCode ? SECRET_IMAGES : IMAGES
    return (
      <div className='container'>
        <Gallery
          images={images}
          enableImageSelection={false}
          backdropClosesModal
          customControls={[]}
        />
      </div>
    )
  }
}

export default Home
